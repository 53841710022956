import {configureStore} from '@reduxjs/toolkit';
import TokenReducer from "./token";
import CaseItemReducer from "./caseItem";

export const store = configureStore({
    reducer: {
        token: TokenReducer,
        caseItem: CaseItemReducer,
    },
});
