import appCss from './App.module.css';
import {Navigate, Route, Routes} from "react-router-dom"
import 'react-toastify/dist/ReactToastify.css';
import JinlonghuHouse from "./pages/Home/AdPage/JinlonghuHouse/JinlonghuHouse";

const App = () => (
    <div className={appCss.App}>
        <Routes>
            <Route path="/JinlonghuHouse" element={<JinlonghuHouse/>}/>
            <Route path='*' element={<Navigate to="/JinlonghuHouse"/>}></Route>
        </Routes>
    </div>
)

export default App;
