import React from 'react';
import css from "./BottomInfoBarForAdPage.module.css"

// 底部信息栏
const BottomInfoBarForAdPage = (props) => {
    return (
        <div className={css.outView}>
            <div className={css.contentView}>
                <div className={css.contentRight}>
                        <div style={{paddingRight: "10px"}}>@2022 手爪网络广告组版权所有</div>
                        <a href="https://beian.miit.gov.cn/" target="_blank">苏ICP备2021052363号-2</a>
                </div>
            </div>
        </div>
    );
}

export default BottomInfoBarForAdPage;
