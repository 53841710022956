import {createSlice} from '@reduxjs/toolkit'

// 一些常量
const tokenStorageName = "4sfszxcdw4grgds";
const userId = "vvs4r2fsjl4jtwl3rj";
// 初始化数据&数据结构
const initialState = {
    token: localStorage.getItem(tokenStorageName),
    userId: localStorage.getItem(userId),
    loading: false,
};
// token 本体
export const token = createSlice({
    name: 'token',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setLoginInfo: (state, action) => {
            state.token = action.payload.token;
            state.userId = action.payload.userId;
            state.loading = false;
            localStorage.setItem(tokenStorageName, action.payload.token ? action.payload.token : null);
            localStorage.setItem(userId, action.payload.userId ? action.payload.userId : null);
        }
    },
});

// select 快捷方式
export const selectToken = (state) => state.token.token; // useSelect(selectToken) 可以使用
export const selectUserId = (state) => state.token.userId; // useSelect(selectToken) 可以使用

// action 快捷方式
export const {setLoginInfo, setLoading} = token.actions; // commit action 设置登录信息

// 导出reducer
export default token.reducer; // 默认导出token的reducer
