// 金龙湖公寓湖景房单价9900起，低首付，准现房，通天燃气， 民用水电，养老，居住，办公，金龙湖湖景尽收眼底
import css from "./JinlonghuHouse.module.css"
import BottomInfoBarForAdPage from "../../../component/ViewBottomInfoBar/BottomInfoBarForAdPage";

const JinlonghuHouse = props => {
    document.title = "金龙湖公寓湖景房";
    return <div className={css.outView}>
        <div className={css.content}>
            <div className={css.text}>金龙湖公寓湖景房<span className={css.hightLeight}>单价9900起</span>，
                <span className={css.hightLeight}>低首付</span>，<span className={css.hightLeight}>准现房</span>，<span className={css.hightLeight}>通天燃气</span>， <span className={css.hightLeight}>民用水电</span>，<span className={css.hightLeight}>养老</span>，<span className={css.hightLeight}>居住</span>，<span className={css.hightLeight}>办公</span>，金龙湖湖景尽收眼底
            </div>
            <div className={css.text}>联系电话：13905213675</div>
            <div className={css.text}>地址：徐州经济技术开发区金龙湖公馆B座一楼大厅&nbsp;&nbsp;<span>
                <a href="https://uri.amap.com/marker?position=117.27503110382841,34.25930209508601&name=金龙湖公馆" target="_blank">地址</a>
            </span></div>
            <img className={css.adImg} src={require("./static/1.jpg")} alt={"广告图1"}/>
            <img className={css.adImg} src={require("./static/2.jpg")} alt={"广告图2"}/>
            <img className={css.adImg} src={require("./static/3.jpg")} alt={"广告图3"}/>
        </div>
        <BottomInfoBarForAdPage/>
    </div>
}
export default JinlonghuHouse;
