import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    data: {data: []},
    loading: false,
    searchParam: {pageNum: 1, pageSize: 9, userType: "", searchKey: ""},
};

// token 本体
export const caseItem = createSlice({
    name: 'caseItem',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setData: (state, action) => {
            state.data = action.payload;
            state.loading = false;
        },
        setSearchParam: (state, action) => {
            state.searchParam = {...state.searchParam, ...action.payload};
        },
    },
});

// select 快捷方式
export const selectCaseItemData = (state) => state.caseItem.data; // useSelect(selectToken) 可以使用

// action 快捷方式
export const {setData, setLoading, setSearchParam} = caseItem.actions; // commit action 设置登录信息

// 导出reducer
export default caseItem.reducer; // 默认导出token的reducer
